
import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"

export default () => {

    const bodyTextColor = '#fff'
    const bodyTitleColor = '#fff'
    const bodyBgColor = '#000'

    const title = 'Page not found'


  return (
    <Layout
      bodyBgColor={bodyBgColor}
      bodyTextColor={bodyTextColor}
      bodyTitleColor={bodyTitleColor}
      pageTitle={title}
    >
        <div className="container mt-32 md:mt-42 text-center self-center">
            <h1>{title}</h1>
            <p className="my-3">Sorry the page you are looking seems to be missing</p>
            <Link to="/" className="inline-block mt-10 px-5 py-2 bg:red">&larr; Back to home</Link>
        </div>
    </Layout>
  )
}
